import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { DeviceListDataProps } from "./DeviceInterface";
import { displayFormats, isActiveStatus } from "../../config/Global";
import moment from "moment";
import { isArray } from "lodash";

export default class DeviceStore {
	agGrid: any = null;
	logAgGrid: any = null
	per_page = Config.grid.local.gridOptions?.paginationPageSize;
	public list_data?: DeviceListDataProps[];
	editValues = null;
	deleteValues = null;
	hardware_list: any = null;
	currentLayoutFilterList: any = null;
	layout_list: any = null;
	log_list: any = null
	initialFormValues = {
		// scaling: 1,
		oriantation: 0
	}

	constructor() {
		makeAutoObservable(this);
	}

	// Setup grid and set column size to autosize
	setupGrid = (params: any) => {
		const columnState = localStorage.getItem("device_token")
		if (columnState) {
			params?.columnApi.applyColumnState({ state: JSON.parse(columnState) });

		}
		// params.api.addEventListener('filterOpened', (event:any) => {
		//     if (event.column.colId === 'layout_data.name') {
		// 		this.agGrid.api.getFilterInstance('layout_data.name', (filter:any) => {
		// 			console.log("434object",filter);
		// 			filter.setParams(this.setCurrentLayoutColumnFilter(this.currentLayoutFilterList));

		// 		});
		//     }
		// });
		this.agGrid = params;
	};

	setCurrentLayoutColumnFilter = (params: any) => {
		const returnValue: any = [];
		params?.map((data: any) => {
			return returnValue.push({
				displayKey: data.name,
				displayName: data.name,
				predicate: (_: null, cellValue: string) =>
					cellValue != null && cellValue.includes(data.name),
				numberOfInputs: 0,
			});
		});
		return { filterOptions: returnValue };
	};

	setCurrentLayoutFilterList = (params: any) => {
		this.currentLayoutFilterList = params;
	};
	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(Number(page));
		}
	};

	logSetupGrid = (params: any) => {
		this.logAgGrid = params;
	};

	setDeleteValues = (data: any) => {
		this.deleteValues = data;
	};

	setEditValues = (id: number) => {
		return axios.post(API_URL.DEVICE.READ_DEVICE + `/${id}`).then(({ data }) => {
			this.editValues = data.data
			return data
		}).catch((response) => {
			return Promise.reject(response);
		})
	};

	setLogtList = (data: any) => {
		return axios.post(API_URL.DEVICE.DEVICE_LOG + `/${data.device_id}`, data).then(({ data }) => {
			if (isArray(data.data)) {
				data.data.forEach((it: any) => {
					it.date = it.date && moment(it.date).format(displayFormats.DATE_MONTH_YEAR)
				})
				this.log_list = data.data
			}
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	// API Functions
	public fetchList = async (data?: any): Promise<any> => {
		return await axios.post(API_URL.DEVICE.LIST, data).then(({ data }) => {
			data.data.forEach((item: any, index: number) => {
				item.display_id = data.data.length > 0 ? data.data.length - index : 0
				item.oriantation = item.oriantation === 1 ? 'Portrait' : 'Landscape'
				item.flipped_orientation = item.flipped_orientation === 0 ? 'No' : 'Yes',
					item.is_active_status = item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
				item.device_active_status = item.device_active_status === 1 ? 'ON' : 'OFF'
				item.last_live_time = item.last_live_time ? moment(item.last_live_time).format(displayFormats.DATE_TIME_FORMAT_Y) : "N/A"
			})
			this.list_data = data.data
		}).catch((response) => {
			return Promise.reject(response);
		});
	};
	onGridChanged = (params: any) => {
		if (params) {
			localStorage.setItem('device_token', JSON.stringify(params?.columnApi?.getColumnState()));
		}
	}
	public currentLayoutColumnFilter = async (): Promise<any> => {

		return await axios
			.post(API_URL.DEVICE.CURRENT_LAYOUT_FILTER_LIST)
			.then(({ data }) => {
				this.setCurrentLayoutFilterList(data.layouts);
				return data.layouts;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};


	public gethardware = () => {
		return axios.get(API_URL.DEVICE.GET_HARDWARE_LIST).then(({ data }) => {
			this.hardware_list = data.data
		}).catch((response) => {
			return Promise.reject(response)
		})
	}
	ActiveStatus = (Id: number, status: number) => {
		return axios.post(API_URL.DEVICE.CHANGE_STATUS + `/${Id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
			this.fetchList()
			return data
		}).catch(({ response: { data } }) => {
			var errors: any = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	AddData = (formData: any) => {
		return axios
			.post(API_URL.DEVICE.ADD, formData)
			.then(({ data }) => {
				this.fetchList();
				return data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	DeleteData = (id: number) => {
		return axios
			.post(API_URL.DEVICE.DELETE + `/${id}`)
			.then(({ data }) => {
				this.fetchList();
				return data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	scheduleOverride = async (payload: any) => {
		return await axios.post(API_URL.DEVICE.SCHEDULE_OVERRIDE, payload).then(({ data }) => {
			this.fetchList();
			return data;
		}).catch((e: any) => {
			return Promise.reject(e)
		})
	}



	EditData = (formData: any, editId: number) => {
		return axios
			.post(API_URL.DEVICE.EDIT + "/" + editId, formData)
			.then(({ data }) => {
				this.fetchList();
				return data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	public getLayoutList = () => {
		return axios.get(API_URL.LAYOUT.LIST).then(({ data }) => {
			this.layout_list = data.data
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	public publishDevice = (data: any) => {
		const id = data.id
		return axios.post(API_URL.DEVICE.PUBLISH_DEVICE + `/${id}`, data).then(({ data }) => {
			this.fetchList();
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	public previewDevice = (data: any) => {
		const id = data.id
		return axios.post(API_URL.DEVICE.PREVIEW_DEVICE + `/${id}`).then(({ data }) => {
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}


	public uninstallDevice = (data: any) => {
		return axios.post(API_URL.DEVICE.DEVICE_UNINSTALL + `/${data}`).then(({ data }) => {
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	public restoreDevice = (data: any) => {
		return axios.post(API_URL.DEVICE.DEVICE_RESTORE + `/${data}`).then(({ data }) => {
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

}
