import React, { useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { observer } from 'mobx-react';
import { ReactComponent as WarningFilled } from "../../../../assets/images/icon/warning.svg"
import useStore from '../../../../store';
import { Notification } from '../../../../config/Global';
import moment from 'moment';

const DeleteComponent: React.FC<any> = observer((props) => {
	const { visible, close, setEvents, deviceId } = props
	const [form] = Form.useForm()
	const { SCHEDULE, SCHEDULE: { selectedComponent, setSelectedComponent } } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState<boolean>(false)

	const handleClose = () => {
		form.resetFields()
		setDisabled(true)
		setSaving(false)
		close()
		if (selectedComponent === "list") {
			setSelectedComponent("list")
		}
		else {
			setSelectedComponent("calendar")
		}
	}

	const handleSubmit = () => {
		setSaving(true)
		SCHEDULE.DeleteData(SCHEDULE?.deleteValues).then((data: any) => {
			if (SCHEDULE.agGrid && selectedComponent === "list") {
				SCHEDULE.fetchList();
				SCHEDULE.setupGrid(SCHEDULE.agGrid)
			} else {
				const startdate = SCHEDULE.startDate && moment(SCHEDULE.startDate).format('YYYY-MM-DD')
				const enddate = SCHEDULE.endDate && moment(SCHEDULE.endDate).format('YYYY-MM-DD')
				const payload = {
					device_id: deviceId,
					start_date: startdate,
					end_date: enddate,
				}
				SCHEDULE.listDeviceSchedule(payload).then((value: any) => {
					setEvents(value)
				})
			}
			Notification.success({
				message: data.data
			})
			// AUTH.fetchAuthUser()	
			handleClose()
		}).catch((e: any) => {
			if (e.errors) {
				form.setFields(e.errors)
			}
		}).finally(() => {
			setSaving(false)
		})
	}

	const handleFieldChange = () => {
		if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	return (
		<Modal
			open={visible}
			onCancel={handleClose}
			width={700}
			title={`Delete Schedule`}
			destroyOnClose={true}
			className="commanModal deleteModal"
			footer={[
				<div key="1">
					<Button className={`${disabled ? 'disableBtn' : 'deleteBtn'}`} form='deleteform' disabled={disabled}
						loading={saving} htmlType="submit" type="primary">Delete</Button>
					<Button className="redBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<Row>
						<Col span={4}><WarningFilled /></Col>
						<Col span={20}>
							<h3>Are you sure you want to delete <strong>{SCHEDULE.editValues?.name}</strong>?</h3>
							<Form.Item style={{ marginBottom: 0 }} name="confirm">
								<Input placeholder='Type "CONFIRM" to delete' onChange={handleFieldChange} />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	)
})

export default DeleteComponent;
