import React from "react";
import { AgGridReact, AgGridReactProps, AgReactUiProps } from "ag-grid-react";
import Config from "../../config/Config";
// import "ag-grid-enterprise";

interface AgGridWrapperProps extends AgReactUiProps, AgGridReactProps { type?: "serverSide" | "clientSide" }

const AgGridWrapper: React.FC<AgGridWrapperProps> = ({ children, type = "clientSide", pinnedBottomRowData = [], components = {}, ...rest }) => {
	const gridType = type === "serverSide" ? Config.grid.server : Config.grid.local;

	return (
		<div className="ag-theme-alpine ag-grid-wrapper" style={{ height: 'calc(100vh - 222px)' }}>
			<AgGridReact
				pinnedBottomRowData={pinnedBottomRowData}
				components={components}
				{...gridType} {...rest}>
				{children}
			</AgGridReact>
		</div>
	);
};

export default AgGridWrapper;
