import { Col, Modal, Row, Spin } from "antd";
import React, { useRef, useState } from "react";
import { InputBox } from "../../../../components/AntdAddons";
import { LayoutRequest } from "../../../../requests/PageRequest";
import Cropper from "react-easy-crop";
import Config from "../../../../config/Config";
import { Notification } from "../../../../config/Global";

interface formInterface {
  visible: boolean;
  cancel: () => void;
  onUpload: (croppedImage: any, originalFileName: string) => void;
  aspectRatio?: number;
  flagBackgroundImage?: boolean;
}

const CropImageUrlElement: React.FC<formInterface> = (props) => {
  const {
    visible,
    cancel,
    onUpload,
    aspectRatio,
    flagBackgroundImage = false,
  } = props;
  const [src, setSrc] = useState<any>(null);
  const [crop, setCrop] = useState<any>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<number>(0); // 0 = no Image show, 1 = loading Image, 2 = show Image
  const timeoutImage = useRef<NodeJS.Timeout | null>(null);

  const cropperDisplay =
    src && imageLoading === 2 ? { display: "flex" } : { display: "none" };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const handleChange = (e: any) => {
    const imageUrl = e.target.value;
    setSrc(imageUrl);
    if (!imageUrl || imageUrl?.length === 0) {
      setImageLoading(0);
    } else {
      setImageLoading(1);
      const validateImage = () => {
        if (timeoutImage.current) clearTimeout(timeoutImage.current);
        timeoutImage.current = setTimeout(() => {
          if (imageUrl?.length !== 0 || imageUrl !== null) {
            createImage(imageUrl)
              .then(() => {
                setImageLoading(2);
              })
              .catch((error) => {
                console.log("error", error);
                setImageLoading(0);
                Notification.error({
                  message: "Invalid Image Url. Try another Image Url",
                });
              });
          }
        }, 2 * 1000);
      };
      validateImage();
    }
  };

  const onOk = async () => {
    setLoading(true);
    try {
      if (!flagBackgroundImage) {
        const croppedImage = await getCroppedImg(src, croppedAreaPixels);
        await onUpload(croppedImage, `cropped_Image_${new Date().getTime()}`);
      } else {
        await createImage(src);
        await onUpload(src, `url_Image_${new Date().getTime()}`);
      }
      cancel();
    } catch (error) {
      console.log("error", error);
      Notification.error({
        message: "something went Wrong. Try another Image Url",
      });
      setImageLoading(0);
    } finally {
      setLoading(false);
    }
  };

  const createImage = (url: any) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.setAttribute("crossOrigin", "anonymous"); // To avoid CORS issues
      image.addEventListener("load", () => {
        resolve(image);
      });
      image.addEventListener("error", (error) => {
        reject(error);
      });
      image.src = url;
    });

  const getCroppedImg = async (src: any, pixelCrop: any) => {
    const image = await createImage(src);
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((file: any) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  return (
    <Modal
      okText="Ok"
      cancelText="Cancel"
      open={visible}
      onOk={onOk}
      okButtonProps={{
        disabled:
          src?.length === 0 ||
          src == null ||
          imageLoading === 0 ||
          imageLoading === 1,
        loading: loading,
      }}
      destroyOnClose={true}
      onCancel={() => {
        if (timeoutImage.current) clearTimeout(timeoutImage.current);
        cancel();
      }}
      className="removeUploadModal commanModal"
    >
      <Row gutter={Config.gutter} className="mt-20">
        <Col span={24}>
          <InputBox.Text
            value={src}
            label={"Image Url"}
            required
            placeholder="Enter The Image Url"
            onChange={handleChange}
            rules={LayoutRequest.description}
          />
        </Col>
      </Row>

      <>
        {imageLoading === 1 && (
          <Row justify="center" className="mt-10">
            <Spin />
          </Row>
        )}

        <Row gutter={Config.gutter} className="mt-10" style={cropperDisplay}>
          <Col span={24} className={"cropperCol"}>
            {!flagBackgroundImage ? (
              <Cropper
                image={src}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            ) : (
              <div className="urlImage">
                <img src={src} />
              </div>
            )}
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default CropImageUrlElement;
