import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { InputBox } from "../../../../components/AntdAddons";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { Col, FormInstance } from "antd";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  layoutIdFlag?:boolean; 
}

const WebsitePreview: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { name, label, onChange, layout_id, form, layoutIdFlag } = props;
 
    useEffect(() => {
      if (layout_id && layoutIdFlag) {
        form.setFieldValue(name, "");
      }
    }, [layout_id]);

    return (
      <Col>
        <InputBox.Text
          name={name}
          label={
            <div>
              {label.charAt(0).toUpperCase() + label.slice(1)}
            </div>
          }
          placeholder={`Enter ${
            label.charAt(0).toUpperCase() + label.slice(1)
          }`}
          rules={LayoutRequest.text}
          onChange={onChange}
          extra="This widget will set the website URL."
        />
      </Col>
    );
  }
);

export default WebsitePreview;
