import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import useStore from "../../../../store";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
// import { faBookOpen, faCloudArrowUp, faExternalLink, faPencilAlt, faPowerOff, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
// import Config from '../../../../config/Config';
import { observer } from "mobx-react";
import { ReactComponent as EditIcon } from "../../../../assets/images/icon/edit.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/images/icon/download.svg";
import { ReactComponent as PublishIcon } from "../../../../assets/images/icon/publish.svg";
import { ReactComponent as PreviewIcon } from "../../../../assets/images/icon/privewScreen.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/icon/delete.svg";
import { ReactComponent as SwitchIcon } from "../../../../assets/images/icon/logout.svg";
import { ReactComponent as OverRideIcon } from "../../../../assets/images/icon/overRideICon.svg";
import { ReactComponent as RestoreIcon } from "../../../../assets/images/icon/restoreIcon.svg";
import { ReactComponent as StatusIcon } from "../../../../assets/images/icon/eyes.svg";
import { ReactComponent as StatusOffIcon } from "../../../../assets/images/icon/eye.svg";
import { Popover, Switch } from "antd";
import { useLocation } from "react-router-dom";
// import { agDateColumnFilter } from "../../../../config/Global";
import {
  agDateTimeColumnFilter,
  isChecked,
  planMessage,
} from "../../../../config/Global";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { CONSTANT } from "../../../../config/Constant";
import { setCustomColumnFilter } from "../../../../config/Config";
import CustomDatePicker from "../../../../utils/DateAndTimeFiler";

const ListComponent: React.FC<any> = observer((prop) => {
  const {
    openEditModal,
    openDeleteModal,
    openLogeModal,
    restoreDevice,
    openSwitchModal,
    OpenPublishscreen,
    openPreviewScreen,
    unInstallDevice,
    ScheduleoverrideDrawer,
    setFilterModal,
  } = prop;
  const {
    DEVICE,
    AUTH,
    // DEVICE: { 
    // currentLayoutFilterList, 
    // currentLayoutColumnFilter 
    // },
  } = useStore();
  const loc = useLocation();
  const flag = loc.search.slice(1);
  const cellStyle = {
    textAlign: "center",
  };

  const deviceActiveStatus = [
    { id: 1, value: "ON" },
    { id: 0, value: "OFF" },
  ];

  const harderWareList = [
    { id: 0, value: "Android Device" },
    { id: 1, value: "Fire TV" },
    // { id: 2, value: "Chromecast" }, //Chrome cast hide
  ];

  const ActionRenderer: React.FC = (prop: any) => {
    return (
      <div className="action-column">
        {AUTH.checkPermission(6, "preview") &&
          // [CONSTANT.Company, CONSTANT.Employee].includes(
          //   AUTH.user.users_roles_id
          // ) &&
          prop.data.layout_data && (
            <Button
              type="text"
              title={"Preview Screen"}
              onClick={() => openPreviewScreen(prop.data)}
            >
              <PreviewIcon />
            </Button>
          )}
        {AUTH.checkPermission(6, "publish") &&
          // [CONSTANT.Company, CONSTANT.Employee].includes(
          //   AUTH.user.users_roles_id
          // ) && 
          (
            <Button
              data-tut="reactour__list_publish_device"
              type="text"
              title={"Publish Device"}
              onClick={() => OpenPublishscreen(prop.data)}
            >
              {/* <FontAwesomeIcon icon={faCloudArrowUp} color={Config.blueBtn} /> */}
              <DownloadIcon />
            </Button>
          )}
        {AUTH.checkPermission(6, "Event log") &&
          // [CONSTANT.Company, CONSTANT.Employee].includes(
          //   AUTH.user.users_roles_id
          // ) ? 
          (
            (
              ![
                CONSTANT.SuperAdmin,
                CONSTANT.SubAdmin,
                CONSTANT.Partner,
              ].includes(AUTH.user.users_roles_id)
                ? AUTH.checkPlan(CONSTANT.PROOF_OF_PLAY)
                : true
            )
              // AUTH.checkPlan(CONSTANT.PROOF_OF_PLAY)
              ? (
                <Button
                  type="text"
                  title={"View log"}
                  className="view_log"
                  onClick={() => openLogeModal(prop.data)}
                >
                  {/* <FontAwesomeIcon icon={faBookOpen} color={Config.greenBtn} /> */}
                  <PublishIcon />
                </Button>
              ) : (
                <Popover title={() => planMessage("View log")}>
                  <Button
                    type="text"
                    disabled={true}
                    title={"View log"}
                    className="view_log"
                    onClick={() => openLogeModal(prop.data)}
                  >
                    {/* <FontAwesomeIcon icon={faBookOpen} color={Config.greenBtn} /> */}
                    <PublishIcon />
                  </Button>
                </Popover>
              )
          )
          // : null
        }
        {AUTH.checkPermission(6, "edit") && (
          <Button
            type="text"
            title={"Edit"}
            className="editIcon"
            onClick={() => openEditModal(prop.data)}
          >
            {/* <FontAwesomeIcon icon={faPencilAlt} color={Config.orangeBtn} /> */}
            <EditIcon />
          </Button>
        )}
        {AUTH.checkPermission(6, "delete") && (
          <Button
            type="text"
            title={"Delete"}
            onClick={() => openDeleteModal(prop.data)}
          >
            {/* <FontAwesomeIcon icon={faTrashAlt} color={Config.redBtn} /> */}
            <DeleteIcon />
          </Button>
        )}
        {AUTH.checkPermission(6, "App uninstall") &&
          // [CONSTANT.Company, CONSTANT.Employee].includes(
          //   AUTH.user.users_roles_id
          // ) && 
          (
            <Button
              type="text"
              title={"Schedule App Uninstall on next Sync"}
              onClick={() => unInstallDevice(prop.data)}
            >
              {/* <FontAwesomeIcon icon={faPowerOff} color={Config.redBtn} /> */}
              <SwitchIcon />
            </Button>
          )}
        {prop.data?.deleted_at &&
          // [CONSTANT.SuperAdmin, CONSTANT.SubAdmin].includes(
          //   AUTH.user.users_roles_id
          // ) && 
          (
            <Button
              type="text"
              title={"Restore Device"}
              onClick={() => restoreDevice(prop?.data)}
            >
              {/* <FontAwesomeIcon icon={faPowerOff} color={Config.redBtn} /> */}
              <RestoreIcon />
            </Button>
          )}
        {AUTH.checkPermission(6, "list") &&
          // [CONSTANT.Company, CONSTANT.Employee].includes(
          //   AUTH.user.users_roles_id
          // ) &&
          prop.data &&
          prop.data?.schedule &&
          prop.data?.schedule.length > 0 &&
          prop.data.layout_id &&
          prop.data.name &&
          AUTH.user.company_id !== 5 && (
            <Button
              type="text"
              title={"Override"}
              onClick={() => ScheduleoverrideDrawer(prop.data)}
            >
              {/* <FontAwesomeIcon icon
					={faPowerOff} color={Config.redBtn} /> */}
              <OverRideIcon />
            </Button>
          )}
      </div>
    );
  };

  const CustomTooltip: React.FC = (params: any) => {
    return (
      <Popover title={params.data.device_active_status}>
        <span className="statusIcon">
          {params.data.device_active_status === "ON" ? (
            <StatusOffIcon className="statusOffIcon" />
          ) : (
            <StatusIcon className="statusOnIcon" />
          )}
        </span>
      </Popover>
    );
  };

  // const setCurrentLayoutColumnFilter = (params: any) => {
  //   const returnValue: any = ["empty"];
  //   params?.map((data: any) => {
  //     return returnValue.push({
  //       displayKey: data.name,
  //       displayName: data.name,
  //       predicate: (_: null, cellValue: string) =>
  //         cellValue != null && cellValue.includes(data.name),
  //       numberOfInputs: 0,
  //     });
  //   });
  //   return { filterOptions: returnValue };
  // };

  useEffect(() => {
    DEVICE.fetchList(flag);
    // currentLayoutColumnFilter();
  }, []);

  const cellRenderData = (data: any) => {
    return (
      AUTH.checkPermission(6, "status") && (
        <Switch
          checked={data.data.status}
          onChange={() => openSwitchModal(data.data)}
        />
      )
    );
  };
  const statusValueFormatter = (params: any) => {
    return parseInt(params.value) === 1 ? "Active" : "Inactive";
  };
  const columnSetup = () => {
    const columns: any[] = [
      <AgGridColumn
        key="id"
        field="id"
        cellStyle={cellStyle}
        headerName="# ID"
        tooltipField="id"
        maxWidth={80}
        filter={"agNumberColumnFilter"}
        sortable={false}
      />,
      <AgGridColumn
        key="pair_code"
        field="pair_code"
        tooltipField="pair_code"
        headerName="Pair Code"
        width={100}
        minWidth={100}
      />,
      <AgGridColumn
        key="device_name"
        field="name"
        tooltipField="name"
        headerName="Device Name"
        width={100}
        minWidth={100}
      />,
      <AgGridColumn
        key="device_active_status"
        field="device_active_status"
        headerName=""
        tooltipField="device_active_status"
        filter="agTextColumnFilter"
        cellStyle={cellStyle}
        headerComponentParams={{
          template: '<div data-tut="reactour__list_is_live">Is Live</div>',
        }}
        filterParams={setCustomColumnFilter(deviceActiveStatus)}
        cellRenderer={CustomTooltip}
        width={70}
        minWidth={70}
      />,
      <AgGridColumn
        key="layout_data.name"
        field="layout_data.name"
        tooltipField="layout_data.name"
        headerName="Current Layout"
        cellRenderer={(params: any) =>
          params.data && params.data.layout_data
            ? params.data.layout_data.name
            : "N/A"
        }
        // filter={"agSetColumnFilter"}
        // filterParams={setCurrentLayoutColumnFilter(currentLayoutFilterList)}
        width={100}
        minWidth={100}
      />,
      <AgGridColumn
        key="last_layout_name.name"
        field="last_layout_name.name"
        headerName="Default Layout"
        tooltipField="last_layout_name.name"
        width={80}
        minWidth={110}
      />,
      <AgGridColumn
        key="last_live_time"
        field="last_live_time"
        tooltipField={
          (
            ![
              CONSTANT.SuperAdmin,
              CONSTANT.SubAdmin,
              CONSTANT.Partner,
            ]?.includes(AUTH.user.users_roles_id)
              ? AUTH?.checkPlan(CONSTANT.LAST_ONLINE_TIME)
              : true
          )
            ? "last_live_time"
            : `Please Upgrade Your Plan`
        }
        // tooltipField={"last_live_time"}
        headerName="Last Live Time"
        filter={"agDateColumnFilter"}
        floatingFilter={false}
        headerClass={
          (
            ![
              CONSTANT.SuperAdmin,
              CONSTANT.SubAdmin,
              CONSTANT.Partner,
            ]?.includes(AUTH?.user?.users_roles_id)
              ? AUTH?.checkPlan(CONSTANT.LAST_ONLINE_TIME)
              : true
          )
            ? ""
            : "headerLastLive"
        }
        cellClass={
          (
            ![
              CONSTANT.SuperAdmin,
              CONSTANT.SubAdmin,
              CONSTANT.Partner,
            ]?.includes(AUTH?.user?.users_roles_id)
              ? AUTH?.checkPlan(CONSTANT.LAST_ONLINE_TIME)
              : true
          )
            ? ""
            : "lastLiveTime"
        }
        headerTooltip={
          (
            ![
              CONSTANT.SuperAdmin,
              CONSTANT.SubAdmin,
              CONSTANT.Partner,
            ]?.includes(AUTH.user.users_roles_id)
              ? AUTH?.checkPlan(CONSTANT.LAST_ONLINE_TIME)
              : true
          )
            ? "last_live_time"
            : `Please Upgrade Your Plan`
        }
        filterParams={agDateTimeColumnFilter}
        width={150}
        minWidth={150}
        cellRenderer={(params: any) => {
          if (
            params.data &&
              ![
                CONSTANT.SuperAdmin,
                CONSTANT.SubAdmin,
                CONSTANT.Partner,
              ].includes(AUTH.user.users_roles_id)
              ? AUTH.checkPlan(CONSTANT.LAST_ONLINE_TIME)
              : true
          ) {
            return params?.data?.last_live_time;
          }
          return <Popover title={() => planMessage()}>N/A</Popover>;
        }}
      />,
      <AgGridColumn
        key="hardware_type"
        field="hardware_type.hardware_name"
        headerName="Hardware Type"
        filter={"agTextColumnFilter"}
        tooltipValueGetter={(params) =>
          params.data.hardware_type_id === 1
            ? "Android Device"
            : params.data.hardware_type_id === 2
              ? "Chromecast"
              : "Fire TV"
        }
        filterParams={setCustomColumnFilter(harderWareList)}
        width={120}
        minWidth={120}
      />,
      <AgGridColumn
        key="orientation"
        field="oriantation"
        tooltipField="oriantation"
        headerName="Orientation"
        filter={"agTextColumnFilter"}
        width={100}
        minWidth={100}
        filterParams={setCustomColumnFilter(CONSTANT.ORIANTATION_VALUE)}
      />,
      <AgGridColumn
        key="flipped_orientation"
        field="flipped_orientation"
        tooltipField="flipped_orientation"
        headerName="Flipped Orientation"
        cellStyle={cellStyle}
        filter="agTextColumnFilter"
        filterParams={setCustomColumnFilter(isChecked)}
        width={80}
        minWidth={80}
      />,
      <AgGridColumn
        key={"status"}
        headerName="Device Status"
        cellStyle={cellStyle}
        field="status"
        tooltipValueGetter={(params) =>
          params.data.status === 1 ? "Active" : "Inactive"
        }
        sortable={false}
        maxWidth={100}
        filterParams={{
          filterOptions: [
            "empty",
            {
              displayKey: "Active",
              displayName: "Active",
              predicate: (_: null, cellValue: number) =>
                cellValue != null && cellValue === 1,
              numberOfInputs: 0,
            },
            {
              displayKey: "Inactive",
              displayName: "Inactive",
              predicate: (_: null, cellValue: number) =>
                cellValue != null && cellValue === 0,
              numberOfInputs: 0,
            },
          ],
        }}
        valueFormatter={statusValueFormatter}
        cellRendererFramework={cellRenderData}
      />,
      <AgGridColumn
        key="users.username"
        field="users.username"
        headerName="Created by"
        tooltipField="users.username"
        width={80}
        minWidth={110}
      />,
      <AgGridColumn
        key="action"
        headerName="Actions"
        type="actionColumn"
        sortable={false}
        filter={false}
        maxWidth={230}
        minWidth={230}
        width={230}
        pinned="right"
      />,
    ];

    // if (![CONSTANT.SuperAdmin].includes(AUTH.user.users_roles_id)? !AUTH.checkPlan(CONSTANT.LAST_ONLINE_TIME) : true) {
    //   columns = columns.filter(column => column.key !== "last_live_time");
    // }
    return columns;
  };

  return (
    // this wrapper used for custom date and time filter
    <AgGridWrapper
      onGridReady={DEVICE.setupGrid}
      components={{ ActionRenderer, agDateInput: CustomDatePicker }}
      rowData={DEVICE.list_data}
      onColumnResized={DEVICE?.onGridChanged}
      onColumnMoved={DEVICE?.onGridChanged}
      onFilterChanged={() => {
        setFilterModal(DEVICE?.agGrid?.api?.getFilterModel());
      }}
    >
      {AUTH.pricing_plan_list && columnSetup()}
    </AgGridWrapper>
  );
});

export default ListComponent;
