import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { ListALlScheduleProps, ListDataScheduleProps } from "./ScheduleInterface";
import Config from "../../config/Config";
import { CONSTANT } from "../../config/Constant";

export default class ScheduleStore {
	agGrid: any = null;
	per_page = Config.grid.local.gridOptions?.paginationPageSize;
	initialValue = {
		type: 1
	}
	layout_list: any = null
	public list_data?: ListDataScheduleProps[]
	public list_all_schedule_data?: ListALlScheduleProps[]
	schedule_layout_list: any = null
	calendar_device_list: any = null;
	editValues: any = null;
	deleteValues: any = null;
	selectedComponent: any = "calendar";
	startDate: any = null;
	endDate: any = null;

	constructor() {
		makeAutoObservable(this)
	}

	// Setup grid and set column size to autosize
	setupGrid = (params: any) => {
		const columnState = localStorage.getItem("schedule_list")
		if (columnState) {
			params.columnApi.applyColumnState({ state: JSON.parse(columnState) });

		}
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(Number(page));
		}
	};

	setDeleteValues = (data: any) => {
		this.deleteValues = data;
	};

	setEditValues = (data: any) => {
		this.editValues = data;
	};

	setSelectedComponent = (data: any) => {
		this.selectedComponent = data;
	};
	onGridChanged = (params: any) => {
		if (params) {
			localStorage.setItem('schedule_list', JSON.stringify(params.columnApi.getColumnState()))
		}
	}

	setStartDate = (data: any) => {
		this.startDate = data;
	};

	setEndDate = (data: any) => {
		this.endDate = data;
	};

	fetchList = async () => {
		return await axios.get(API_URL.SCHEDULE.ALL_SCHEDULE_LIST).then(({ data }) => {
			data?.scheduleData?.map((data: any) => {
				if (data.type === CONSTANT.SCHEDULE.TYPE.REPEAT) {
					const weekDay = CONSTANT.SCHEDULE.WEEK_DAYS.filter((value) => { return value.id === data.week_day })[0].value;
					data.start_date_time = weekDay + " " + data.start_date_time
					data.end_date_time = weekDay + " " + data.end_date_time
				}
			})
			this.list_all_schedule_data = data?.scheduleData
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}


	addSchedule = (data: any) => {
		return axios.post(API_URL.SCHEDULE.ADD_SCHEDULE, data).then(({ data }) => {
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	ReadData = (id: number) => {
		return axios
			.post(`${API_URL.SCHEDULE.READ}/${id}`)
			.then(({ data }) => {
				this.setEditValues(data.data);
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	DeleteData = async (id: number) => {
		return axios
			.post(`${API_URL.SCHEDULE.DELETE}/${id}`)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}

	EditData = (formData: any) => {
		return axios
			.post(API_URL.SCHEDULE.EDIT + "/" + this.editValues?.id, formData)
			.then(({ data }) => {
				this.editValues = null;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}

	listSchedule = async () => {
		return await axios.get(API_URL.SCHEDULE.LIST_LAYOUT).then(({ data }) => {
			this.layout_list = data.data
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	listDeviceSchedule = (data: any) => {
		return axios.post(API_URL.SCHEDULE.LIST_DEVICE, data).then(({ data }) => {
			data?.scheduleData.forEach((it: any) => {
				if (it.type === 3) {
					it['start'] = it.start_date
					delete it.start_date
					it['backgroundColor'] = '#ffc107'
				} else {
					it['start'] = it.start_date
					it['end'] = it.end_date
					delete it.start_date
					delete it.end_date
					it['backgroundColor'] = it.type === 1 ? '#5867dd' : '#36a3f7'
				}
			})
			this.list_data = data?.scheduleData

			return data?.scheduleData
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	listLayoutSchedule = async (type?: string) => {
		return await axios.get(API_URL.SCHEDULE.DEVICE_LIST).then(({ data }) => {
			if (type === "calendar" && data.data) {
				const calendarDeviceList = data.data;
				if (data.data.length > 0) {
					calendarDeviceList.unshift({ id: 0, name: "All Devices" })
				}
				this.calendar_device_list = calendarDeviceList;
			}
			this.schedule_layout_list = data.data
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

}


