import { faLink, faPenNib } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  FormInstance,
  message,
  Modal,
  Popover,
  Upload,
} from "antd";
import { RcFile, UploadFile } from "antd/lib/upload";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { StyleProps } from "../../../../store/LayoutStore/LayoutInterface";
import { UploadOutlined } from "@ant-design/icons";
import StyleModalComponent from "../components/StyleModal";
import {
  createUrl,
  planMessage,
  uploadedFileOnPreview,
  widgetStyles,
} from "../../../../config/Global";
import useStore from "../../../../store";
import { CONSTANT } from "../../../../config/Constant";
import GenerateAIImage from "../components/GenerateAIImage";
import CropImageUrlElement from "../components/CropImageUrlElement";
// import ImgCrop from "antd-img-crop";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  logo: any;
  setShow?: (data: any) => void;
  layoutIdFlag?: any;
}

const LogoElement: React.FC<formInterface> = observer((props) => {
  const {
    form,
    onChange,
    name,
    label,
    logo,
    layout_id,
    setShow,
    layoutIdFlag,
  } = props;
  const [styleProps, setStyleProps] = useState<StyleProps>({
    styles: [],
    type: "",
  });
  const [applyStyleModal, setApplyStyleModal] = useState<boolean>(false);
  const [applyGenerateAIImageModal, setApplyGenerateAIImageModal] =
    useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [msg, setMsg] = useState("");
  const { LAYOUT, AUTH } = useStore();
  const [dataV, setDataV] = useState();
  const [visible, setVisible] = useState<boolean>(false);
  const [applyImageUrlModal, setApplyImageUrlModal] = useState<boolean>(false);
  // const [aspectRatio, setAspectRatio] = useState<number>(1 / 1);

  const openStyleModal = (data: StyleProps) => {
    setStyleProps(data);
    setApplyStyleModal(true);
  };

  const closeStyleModal = () => {
    setApplyStyleModal(false);
  };

  useEffect(() => {
    if (LAYOUT.editValues && form.getFieldValue(name)) {
      setFileList([
        {
          uid: "-1",
          name: form.getFieldValue(name),
          status: "done",
          url: createUrl("/secure/media/file/" + form.getFieldValue(name), AUTH.awsS3Url),
        },
      ]);
    }
  }, [LAYOUT.editValues]);

  useEffect(() => {
    if (LAYOUT.listAiImages && LAYOUT.listAiImages?.field_name === name) {
      const aiImages1 = LAYOUT?.listAiImages?.images?.map((value: number) => {
        const list = LAYOUT?.aiImage?.find((data: number, index: number) => {
          return index === value;
        });
        return list;
      });

      const fileList = aiImages1?.map((data: any) => ({
        uid: "-1",
        name: `${data?.name}`,
        status: "done",
        url: data?.image_resource_url,
      }));

      setFileList(fileList);
      form.setFieldsValue({
        [name]: {
          aiGenerated: {
            type: "aiGenerated",
            name: fileList[0]?.name,
            url: fileList[0]?.url,
          },
        },
      });
      onChange();
    }
  }, [LAYOUT.listAiImages]);

  useEffect(() => {
    if (layout_id && layoutIdFlag) {
      setFileList([]);
      form.setFieldsValue({ [name]: null });
    }
  }, [layout_id]);

  useEffect(() => {
    setMsg("");
    // setAspectRatio(1 / 1);
    if ([53]?.includes(layout_id)) {
      setMsg("Required Logo Height=244 & Width=244");
      // setAspectRatio(244 / 244);
    } else if ([2, 29]?.includes(layout_id)) {
      setMsg("Required Image Height = 110 & Width = 110");
      // setAspectRatio(110 / 110);
    } else if ([4]?.includes(layout_id)) {
      setMsg("Required Image Height = 170 & Width = 170");
      // setAspectRatio(170 / 170);
    } else if ([5, 28]?.includes(layout_id)) {
      setMsg("Required Image Height = 270 & Width = 270");
      // setAspectRatio(270 / 270);
    } else if (["logo-1"]?.includes(logo) && [9]?.includes(layout_id)) {
      setMsg("Required Image Height = 290 & Width = 180");
      // setAspectRatio(180 / 290);
    } else if (["logo-2"]?.includes(logo) && [9]?.includes(layout_id)) {
      setMsg("Required Image Height = 340 & Width = 180");
      // setAspectRatio(180 / 340);
    } else if ([10]?.includes(layout_id)) {
      setMsg("Required Image Height = 380 & Width = 280");
      // setAspectRatio(280 / 380);
    } else if ([11]?.includes(layout_id)) {
      setMsg("Required Image Height = 290 & Width = 130");
      // setAspectRatio(130 / 290);
    } else if ([6, 7]?.includes(layout_id)) {
      setMsg("Required Image Height = 165 & Width = 165");
      // setAspectRatio(165 / 165);
    } else if ([1, 3, 13, 15, 17, 37]?.includes(layout_id)) {
      setMsg("Required Image Height = 220 & Width = 220");
      // setAspectRatio(220 / 220);
    } else if ([16]?.includes(layout_id)) {
      setMsg("Required Image Height = 770 & Width = 190");
      // setAspectRatio(190 / 770);
    } else if (["logo-1"]?.includes(logo) && [8]?.includes(layout_id)) {
      setMsg("Required Image Height = 160 & Width = 160");
      // setAspectRatio(160 / 160);
    } else if (["logo-2"]?.includes(logo) && [8]?.includes(layout_id)) {
      setMsg("Required Image Height = 250 & Width = 250");
      // setAspectRatio(250 / 250);
    } else if ([27]?.includes(layout_id)) {
      setMsg("Required Image Height = 120 & Width = 210");
      // setAspectRatio(210 / 120);
    } else {
      setMsg("You can select jpg or png or jpeg image file for this widget");
    }
  }, [layout_id]);

  //For showing modal on file removal :Start
  const toggle = () => {
    setVisible((prev) => !prev);
    return false;
  };

  const deleteItem = () => {
    if (typeof form.getFieldValue(name) !== "object") {
      const imageFieldId = LAYOUT.editValues.layout_field.find(
        (data: any) => data.shortcode.split("_")[0] === name
      ).id;
      const payload = {
        file_path: "/storage/media/file/" + form.getFieldValue(name),
        layout_field_id: imageFieldId,
      };
      LAYOUT.RemoveFileApi(LAYOUT.editValues.id, payload);
      toggle();
    }
    setFileList([]);
    form.resetFields([name]);
    onChange();
    return true;
  };
  //For showing modal on file removal :End

  // For upload image through web url
  const handleUpload = async (
    croppedImage: string,
    originalFileName: string
  ) => {
    const response = await fetch(croppedImage);
    const blob = await response.blob();

    const newFile: any = [
      {
        uid: "-1",
        name: `${originalFileName}.jpeg`,
        status: "done",
        url: croppedImage,
      },
    ];
    setFileList(newFile);

    const fileWithOriginalName = new File(
      [blob],
      `${originalFileName}.jpeg`,
      { type: blob.type }
    );
    form.setFieldsValue({
      [name]: fileWithOriginalName,
    });

    onChange();
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <Col>
        <Form.Item
          name={name}
          className="full-width-dragger"
          label={
            <div>
              {label.charAt(0).toUpperCase() + label.slice(1)}
              {AUTH.checkPlan(CONSTANT.INTERACTIVE_CONTENT_SUPPORT) ? (
                <>
                  <Button
                    type="primary"
                    title="Apply style"
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.logo(),
                        type: name,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                  {/* <Button
                    type="primary"
                    title="Generate AI Image"
                    onClick={() => setApplyGenerateAIImageModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    Generate AI Image
                  </Button> */}
                  <Button
                    type="primary"
                    title="Image Url"
                    onClick={() => setApplyImageUrlModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </>
              ) : (
                <Popover title={() => planMessage()}>
                  <Button
                    type="primary"
                    disabled={true}
                    title="Apply style"
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.logo(),
                        type: name,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                  {/* <Button
                    type="primary"
                    title="Generate AI Image"
                    disabled={true}
                    onClick={() => setApplyGenerateAIImageModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    Generate AI Image
                  </Button> */}
                  <Button
                    type="primary"
                    title="Image Url"
                    disabled={true}
                    onClick={() => setApplyImageUrlModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </Popover>
              )}
            </div>
          }
          extra={msg}
          rules={LayoutRequest.image}
        >
          {/* <ImgCrop aspect={aspectRatio}> */}
          <Upload
            accept=".png, .jpg, .jpeg"
            listType="picture"
            customRequest={dummyRequest}
            multiple={false}
            fileList={fileList}
            onRemove={
              typeof form.getFieldValue(name) !== "object"
                ? toggle
                : deleteItem
            }
            onPreview={uploadedFileOnPreview}
            showUploadList={{ showPreviewIcon: false }}
            onChange={({ fileList }: any) => {
              setFileList(fileList);
              form.setFieldsValue({
                [name]: fileList[0]?.originFileObj,
              });
              onChange();
            }}
            beforeUpload={(file: RcFile) => {
              const isJpgOrPng =
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png";
              const isLt1M = file.size / 1024 / 1024 < 9;

              if (!isJpgOrPng) {
                message.error(
                  `Upload valid image. Only JPG, JPEG or PNG are allowed.`
                );
                return Upload.LIST_IGNORE;
              } else if (!isLt1M) {
                message.error("File size should not be more than 9 MB");
                return Upload.LIST_IGNORE;
              } else {
                setFileList([file]);
                return false;
              }
            }}
          >
            {fileList.length < 1 && (
              <Button icon={<UploadOutlined />}>Upload</Button>
            )}
          </Upload>
          {/* </ImgCrop> */}
        </Form.Item>
        {applyStyleModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <StyleModalComponent
              setDataV={setDataV}
              dataV={dataV}
              setShow={setShow}
              visible={applyStyleModal}
              close={closeStyleModal}
              styleProps={styleProps}
            />
          </Form.Item>
        )}
        {applyGenerateAIImageModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <GenerateAIImage
              type={"single"}
              name={name}
              visible={applyGenerateAIImageModal}
              close={() => {
                setApplyGenerateAIImageModal(false);
              }}
            />
          </Form.Item>
        )}
        {applyImageUrlModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <CropImageUrlElement
              visible={applyImageUrlModal}
              onUpload={handleUpload}
              cancel={() => {
                setApplyImageUrlModal(false);
              }}
              // aspectRatio={aspectRatio}
              flagBackgroundImage={true} //flag to bypass the cropping

            />
          </Form.Item>
        )}
      </Col>
      <Modal
        okText="Yes"
        cancelText="No"
        open={visible}
        onOk={deleteItem}
        onCancel={toggle}
        className="removeUploadModal commanModal"
      >
        <h4>Are you sure you want to permanently remove this Item ?</h4>
      </Modal>
    </>
  );
});

export default LogoElement;
