const API_URL = {
	// AUTH api url
	LOGIN: "login",
	ME: 'me',
	LOGOUT: "logout",
	FORGET_PASSWORD: 'forget-password',
	UPDATE_PROFILE: 'update-profile',
	UPDATE_PASSWORD: 'user/updatenewpassword',
	CHANGE_PASSWORD: 'change-password',
	DASHBOARD_COUNT: 'dashboard/count',
	THEME: 'user/theme',
	USER_ROLE: {
		ROLELIST: 'getRoleList',
		LIST: "user/list",
		ADD: "user/add",
		DELETE: "user/delete",
		READ: "user/read",
		EDIT: "user/edit",
		PROXY_LOGIN: '/proxyLogin',
		ADD_LICENSE: 'license/add',
		READ_LICENSE: 'license/read',
		UPDATE_LICENSE: 'license/update',
		USER_STATUS: 'user/status',
		COUNTRY_LIST: 'user/country',
		STATE_LIST: 'user/state',
		CITY: 'user/city',
		USER_PERMISSION : '/user/permission'
	},

	DEVICE: {
		LIST: "device/list",
		ADD: "/device/add",
		EDIT: "device/edit",
		DELETE: "device/delete",
		GET_HARDWARE_LIST: "device/hardware",
		READ_DEVICE: 'device/read',
		CHANGE_STATUS: 'device/change-status',
		PUBLISH_DEVICE: 'device/publish',
		DEVICE_LOG: 'device/log',
		PREVIEW_DEVICE: 'device/preview',
		DEVICE_UNINSTALL: 'device/uninstall',
		DEVICE_RESTORE: 'device/restore',
		SCHEDULE_OVERRIDE: 'device/override',
		DEVISE_PERMISSIONS : 'device/permission',
		CURRENT_LAYOUT_FILTER_LIST: "device/layoutIdList",
	},

	RATE: {
		LIST: "rate/list",
		ADD: "rate/add",
		EDIT: "rate/update",
		DELETE: "rate/delete",
		BULK_READ: 'rate/bulk-read',
		BULK_EDIT: 'rate/bulk-edit',
		PUBLISH_RATE: 'rate/publish'
	},
	GLOBAL_POPUP: {
		READ: 'popup/read',
		UPDATE: 'popup/update',
		PUBLISH_TIMING: 'popup/timing',
		PUBLISH_ALLTV: 'popup/publish'
	},
	SETTINGS: {
		READ: 'setting/read',
		UPDATE: 'setting/popup'
	},
	LAYOUT: {
		LAYOUTLIST: "getGlobalLayoutList",
		LIST: "layout/list",
		ADD: "layout/add",
		READ: "layout/read",
		EDIT: "layout/edit",
		DELETE: "layout/delete",
		PUBLISH: "layout/publish",
		DEVICE: "layout/devicelist",
		DEVICE_FILTER_LIST: "layout/device-list",
		SAVEDLAYOUT: 'saved_layouts/save',
		PREVIEW_LAYOUT: 'layout/preview',
		PUBLISH_LAYOUT_WITHOUT_MEDIA: `layout/publish-layout-without-media`,
		CLONE : 'layout/clone',
		FILE_UPLOAD: 'file/upload',
		LAYOUT_PERMISSION : '/layout/permission',
		LAYOUT_NAME:'/layout/name-list',
		LAYOUT_AI:'https://api.edenai.run/v2/image/generation',
		REMOVE_FILE:'/layout/remove-file'
	},
	SCHEDULE: {
		ADD_SCHEDULE: 'schedule/add',
		READ: "schedule/read",
		EDIT: "schedule/edit",
		DELETE: "schedule/delete",
		LIST_LAYOUT: 'layout/listing',
		LIST_DEVICE: 'api/schedule-event',
		DEVICE_LIST: 'layout/devicelist',
		ALL_SCHEDULE_LIST: '/api/all-schedule-event'
	},
	SAVED_LAYOUT: {
		LIST: 'saved_layouts/list',
		DELETE: 'saved_layouts/delete',
		PUBLISH: "saved_layouts/publish",
		CLONE: "saved_layouts/clone",
		PREVIEW_SAVEDLAYOUT: 'saved_layouts/preview'
	},
	ROLE: {
		LIST: 'role/list',
		ADD: 'role/add',
		PERMISSION: 'permission/getpermissionlist',
		EDIT: 'role/edit',
		DELETE: 'role/delete',
		STATUS: 'role/status'
	},
	TOKEN: {
		READ: 'token/read',
		UPDATE: 'token/save'
	},

	DEVICE_GROUP: {
		LIST: "group_device/list",
		ADD: "group_device/add",
		EDIT: "group_device/edit",
		DELETE: "group_device/delete",
		READ: "group_device/read",
		CHANGE_STATUS: 'group_device/change-status',
		GROUP_DEVICE_DROPDOWN: 'group_device/group-device-dropdown',
		PUBLISH_DEVICE: 'group_device/publish',
	},

	AUDIT_LOG: {
		LIST: "audit-list/list",
		READ: "audit-list/read",
	},

	UPDATE_APK: {
		READ: 'apk/read',
		UPLOAD: 'apk/upload'
	},

	SUBSCRIPTION : {
		PlanList : 'plan/list',
		PRICING_PLAN : "pricing-plan"
	},

	STRIPE : {
		SUBSCRIPTION : "subscription/add",
		SUBSCRIPTIONLIST : 'subscription/list',
		CANCEL : 'subscription/cancel'
	},
	ANALYTICS : {
		LAYOUT_ANALYTICS : "layout/analytics",
		GROUP_ANALYTICS : "device/analytics",
		LAYOUT_DETAILS : "layout/detail" ,
		GROUP_DETAILS : "device/detail"
	},
	SOCIAL : {
		LOGIN  : "social/login"
	}
};

export default API_URL;
