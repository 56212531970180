import { Button, Modal, Form, Row, Col, Spin, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { InputBox, FormBox } from "../../../../components/AntdAddons";
import { AddLicensevalidation } from "../../../../requests/PageRequest";
import moment from "moment";
import { CustomerComponentProps } from "../../../../store/CustmerStore/CustomerInterface";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";
import { Notification, displayFormats, validateFields } from "../../../../config/Global";

const EditLicense: React.FC<CustomerComponentProps> = observer(
  (props: CustomerComponentProps) => {
    const { close, visible } = props;
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [form] = Form.useForm();
    const { USER, ROOT, SUBSCRIPTION } = useStore();
    const { editLicense } = USER;
    const [planList, setPlanList] = useState<any>([]);
    const [durationList, setDurationList] = useState<any>([]);
    const [intervalCount, setIntervalCount] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const closeModal = () => {
      setDisabled(true);
      close();
    };

    const handleSubmit = (data: any) => {
      setSaving(true);
      data.company_id = visible?.id;
      data.expiration_date = moment(data.expiration_date).format(
        displayFormats.TEMP_DATE_FORMAT
      );
      data.interval_count = intervalCount;
      editLicense(data)
        .then((data: any) => {
          if (data.data === "License Update Successfully!") {
            close();
            form.resetFields();
            Notification.success({
              message: data.data,
              placement: "bottomRight",
            });
          } else {
            Notification.error({
              message: data.data,
              placement: "bottomRight",
            });
          }
        })
        .catch((e: ErrorProps) => {
          ROOT.assignErrorToInput(form, e?.data);
        })
        .finally(() => setSaving(false));
    };

    useEffect(() => {
      setDisabled(true);
      form.setFieldsValue({
        no_of_devices: USER?.addlicViews?.no_of_devices,
        expiration_date: moment(USER?.addlicViews?.expiry_date),
        payment_details: USER?.addlicViews?.payment_details,
        duration: USER?.addlicViews?.duration,
        plan_name: USER?.addlicViews?.plan_name,
        quantity: Number(USER?.addlicViews?.quantity),
        amount:
          Number(USER?.addlicViews?.amount) *
          Number(USER?.addlicViews?.no_of_devices),
        plan_mode: Number(USER?.addlicViews?.plan_mode),
      });
    }, [form, USER?.addlicViews]);

    const handleChange = () => {
      validateFields(form, setDisabled);
    };

    useEffect(() => {
      if (visible) {
        setLoading(true)
        SUBSCRIPTION.planFetchList().then(()=>{
          handleSelectList()
        }).finally(()=>{
          setLoading(false)
        });
      }
    }, [visible]);

    const handleSelectList = () => {
      const Plan_name: { id: number; name: string }[] = [];
      const duration_list: { id: number; name: string }[] = [];

      SUBSCRIPTION.PlanList?.forEach((item: any) => {
        Plan_name.push({ id: item.name, name: item.name });
      });
      SUBSCRIPTION.PlanList[0].price?.forEach((item: any) => {
        duration_list.push({
          id: item.interval_count.toString() + item.interval,
          name: item.interval_count.toString() + item.interval,
        });
      });
      setPlanList(Plan_name);
      setDurationList(duration_list);
    };

    const handleChangeAmount = () => {
      const duration = form.getFieldValue("duration");
      const qantity = form.getFieldValue("no_of_devices");
      const planName = form.getFieldValue("plan_name");

      let amount: any = Number(USER?.addlicViews?.amount)
        ? Number(USER?.addlicViews?.amount)
        : 0;
      SUBSCRIPTION.PlanList.forEach((item: any) => {
        if (item.name === planName) {
          item.price.forEach((price: any) => {
            if (price.interval_count.toString() + price.interval === duration) {
              setIntervalCount(price.interval_count);
              amount = price.amount / 100;
            }
          });
        }
      });
      if (duration && qantity && planName) {
        form.setFieldValue("amount", amount * Number(qantity));
      }
    };
    return (
      <>
        <Modal
          title="Edit License"
          centered
          open={visible}
          destroyOnClose
          className="commanModal editLicense"
          onCancel={closeModal}
          footer={[
            <Button
              key={1}
              form="close"
              className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
              disabled={disabled}
              loading={saving}
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>,
            <Button
              key={2}
              onClick={closeModal}
              className="orangeBgBtn cancelBtn"
            >
              Cancel
            </Button>,
          ]}
        >
          <FormBox form={form} onFinish={handleSubmit} id="close">
            <div className="deviceDetails">
              {USER?.addlicViews?.no_of_devices && (
                <p>
                  Number of devices allowed:{" "}
                  <span> {USER?.addlicViews?.no_of_devices}</span>
                </p>
              )}
              <p>
                {" "}
                Number of active devices :{" "}
                <span>{USER?.addlicViews?.no_active_device}</span>
              </p>
              <p>
                Expiration Date :{" "}
                <span>
                  {moment(USER?.addlicViews?.expiry_date).format("ll")}
                </span>
              </p>
            </div>
            <Row gutter={15}>
              {/* <Col span={12}>
              <InputBox.Text
                label="No of Devices"
                placeholder="Num of devices"
                name={"no_of_devices"}
                onChange={() => {
                  handleChange();
                  handleChangeAmount();
                }}
                required
                rules={AddLicensevalidation.noOfDevices}
              ></InputBox.Text>
            </Col> */}
              <Col span={12}>
                <InputBox.Select
                  required
                  label="Plan Name"
                  name={"plan_name"}
                  placeholder="plan Name"
                  showSearch
                  notFoundContent={
                    loading ? (
                      <Spin size="default" />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                    )
                  }
                  onChange={() => {
                    handleChange();
                    handleChangeAmount();
                  }}
                  className="selectDown"
                  showArrow={false}
                  // extra={
                  // 	<DownIcon />
                  // }
                  options={{
                    list: planList && planList,
                    textKey: "name",
                    valueKey: "id",
                  }}
                  rules={AddLicensevalidation.PlanName}
                ></InputBox.Select>
              </Col>
              <Col span={12}>
                <InputBox.Select
                  required
                  label="Duration"
                  name={"duration"}
                  placeholder="duration"
                  showSearch
                  onChange={() => {
                    handleChange();
                    handleChangeAmount();
                  }}
                  notFoundContent={
                    loading ? (
                      <Spin size="default" />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                    )
                  }
                  className="selectDown"
                  showArrow={false}
                  // extra={
                  // 	<DownIcon />
                  // }
                  options={{
                    list: durationList && durationList,
                    textKey: "name",
                    valueKey: "id",
                  }}
                  rules={AddLicensevalidation.duration}
                ></InputBox.Select>
              </Col>
              <Col span={12}>
                <InputBox.Number
                  label="No of Devices"
                  placeholder="Num of devices"
                  name={"no_of_devices"}
                  onChange={() => {
                    handleChange();
                    handleChangeAmount();
                  }}
                  min={1}
                  required
                  rules={AddLicensevalidation.noOfDevices}
                ></InputBox.Number>
              </Col>
              <Col span={12}>
                <InputBox.Text
                  label="Amount"
                  placeholder="amount"
                  name={"amount"}
                  onChange={handleChange}
                  required
                  rules={AddLicensevalidation.amount}
                ></InputBox.Text>
              </Col>
              <Col span={12}>
                <InputBox.Select
                  required
                  label="Payment Mode"
                  name={"plan_mode"}
                  placeholder="payment Mode"
                  showSearch
                  onChange={handleChange}
                  className="selectDown"
                  showArrow={false}
                  filterOption={(input: any, option: any) =>
                    (option?.children || {})
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  // extra={
                  // 	<DownIcon />
                  // }
                  options={{
                    list: [
                      { id: 0, name: "Cash" },
                      { id: 1, name: "Online" },
                    ],
                    textKey: "name",
                    valueKey: "id",
                  }}
                  rules={AddLicensevalidation.planMode}
                ></InputBox.Select>
              </Col>
              <Col span={24}>
                <InputBox.Text
                  required
                  label="Payment Description"
                  onChange={handleChange}
                  rules={AddLicensevalidation.payment}
                  name={"payment_details"}
                  placeholder="Payment Description"
                ></InputBox.Text>
              </Col>
            </Row>
          </FormBox>
        </Modal>
      </>
    );
  }
);
export default EditLicense;
