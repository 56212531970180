import React, { useEffect, useState } from "react";
import { Button, Form, Drawer, Spin } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import useStore from "../../../../store";
import { displayFormats, Notification, validateFields } from "../../../../config/Global";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";
// import { ReactComponent as DownIcon } from '../../../../assets/images/icon/downIcon.svg';
import {
	FadeInSectionProps,
} from "../../../../store/ScheduleStore/ScheduleInterface";
import FormComponent from "./FormComponent";
import { CONSTANT } from "../../../../config/Constant";

const EditComponent: React.FC<FadeInSectionProps> = observer(
	(props: FadeInSectionProps) => {
		const { visible, close, setEvents, deviceId } = props;
		const [form] = Form.useForm();
		const [disabled, setDisabled] = useState(true);
		const [tableData, setTableData] = useState<any>();
		const { SCHEDULE, ROOT, SCHEDULE: { selectedComponent, setSelectedComponent } } = useStore();
		const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
		const [show, setShow] = useState<boolean>(true);
		const [saving, setSaving] = useState<boolean>(false);

		const handleClose = () => {
			close()
			form.resetFields()
			setDisabled(true)
			setSelectedRowKeys([])
			setTableData([])
			setShow(true)
			if (selectedComponent === "list") {
				setSelectedComponent("list")
			}
			else {
				setSelectedComponent("calendar")
			}
		}

		const handleSubmit = (data: any) => {
			setSaving(true);
			if (show) {
				data.start_date = moment(data.start_date_time).format('YYYY-MM-DD HH:mm:ss')
				data.end_date = moment(data.end_date_time).format('YYYY-MM-DD HH:mm:ss')
				data.start_time = moment(data.start_date_time).format('HH:mm:ss')
				data.end_time = moment(data.end_date_time).format('HH:mm:ss')
				delete data.start_date_time
				delete data.end_date_time

			} else {
				data.week_day = tableData
				const start_time: any = []
				const end_time: any = []
				Object.keys(data).map((it: any) => {
					if (!['device_id', 'layout_id', 'type', 'week_day'].includes(it)) {
						if (it.split("_")[1] === 'start') {
							start_time.push(moment(data[it]).format('HH:mm:ss'))
							delete data[it]
						}
						if (it.split("_")[1] === 'end') {
							end_time.push(moment(data[it]).format('HH:mm:ss'))
							delete data[it]
						}
					}
				})
				data.start_time = start_time
				data.end_time = end_time
			}
			SCHEDULE.EditData(data).then((data: any) => {
				if (SCHEDULE.agGrid && selectedComponent === "list") {
					SCHEDULE.fetchList();
					SCHEDULE.setupGrid(SCHEDULE.agGrid)
				} else {
					const startdate = SCHEDULE.startDate && moment(SCHEDULE.startDate).format('YYYY-MM-DD')
					const enddate = SCHEDULE.endDate && moment(SCHEDULE.endDate).format('YYYY-MM-DD')
					const payload = {
						device_id: deviceId,
						start_date: startdate,
						end_date: enddate,
					}
					SCHEDULE.listDeviceSchedule(payload).then((value: any) => {
						setEvents(value)
					})
				}
				Notification.success({
					message: data.data
				})
				handleClose()
			}).catch((e: ErrorProps) => {
				ROOT.assignErrorToInput(form, e?.data)
			}).finally(() => setSaving(false));
		}

		const handleChange = () => {
			validateFields(form, setDisabled);
		};

		useEffect(() => {
			form.setFieldsValue({
				device_id: SCHEDULE.editValues?.device_id,
				layout_id: SCHEDULE.editValues?.layout_id,
				type: SCHEDULE.editValues?.type,
			})
			if (SCHEDULE.editValues?.type == CONSTANT.SCHEDULE.TYPE.ONCE) {
				form.setFieldsValue({
					start_date_time: moment(SCHEDULE.editValues?.start_date_time, displayFormats.DATE_FORMAT_Y),
					end_date_time: moment(SCHEDULE.editValues?.end_date_time, displayFormats.DATE_FORMAT_Y)
				})
			}

			if (SCHEDULE.editValues?.type == CONSTANT.SCHEDULE.TYPE.REPEAT) {
				setShow(false)
				if (SCHEDULE.editValues.week_day === 0) {
					setTableData(["0"]);
					setSelectedRowKeys(["0"])
					form.setFieldsValue({
						mon_start_time: moment(SCHEDULE.editValues?.start_date_time.trim(), displayFormats.TIME_FORMAT_Y),
						mon_end_time: moment(SCHEDULE.editValues?.end_date_time.trim(), displayFormats.TIME_FORMAT_Y)
					})
				}
				if (SCHEDULE.editValues.week_day === 1) {
					setTableData(["1"]);
					setSelectedRowKeys(["1"])
					form.setFieldsValue({
						tue_start_time: moment(SCHEDULE.editValues?.start_date_time.trim(), displayFormats.TIME_FORMAT_Y),
						tue_end_time: moment(SCHEDULE.editValues?.end_date_time.trim(), displayFormats.TIME_FORMAT_Y)
					})
				} if (SCHEDULE.editValues.week_day === 2) {
					setTableData(["2"]);
					setSelectedRowKeys(["2"])
					form.setFieldsValue({
						wed_start_time: moment(SCHEDULE.editValues?.start_date_time.trim(), displayFormats.TIME_FORMAT_Y),
						wed_end_time: moment(SCHEDULE.editValues?.end_date_time.trim(), displayFormats.TIME_FORMAT_Y)
					})
				} if (SCHEDULE.editValues.week_day === 3) {
					setTableData(["3"]);
					setSelectedRowKeys(["3"])
					form.setFieldsValue({
						thu_start_time: moment(SCHEDULE.editValues?.start_date_time.trim(), displayFormats.TIME_FORMAT_Y),
						thu_end_time: moment(SCHEDULE.editValues?.end_date_time.trim(), displayFormats.TIME_FORMAT_Y)
					})
				} if (SCHEDULE.editValues.week_day === 4) {
					setTableData(["4"]);
					setSelectedRowKeys(["4"])
					form.setFieldsValue({
						fri_start_time: moment(SCHEDULE.editValues?.start_date_time.trim(), displayFormats.TIME_FORMAT_Y),
						fri_end_time: moment(SCHEDULE.editValues?.end_date_time.trim(), displayFormats.TIME_FORMAT_Y)
					})
				} if (SCHEDULE.editValues.week_day === 5) {
					setTableData(["5"]);
					setSelectedRowKeys(["5"])
					form.setFieldsValue({
						sat_start_time: moment(SCHEDULE.editValues?.start_date_time.trim(), displayFormats.TIME_FORMAT_Y),
						sat_end_time: moment(SCHEDULE.editValues?.end_date_time.trim(), displayFormats.TIME_FORMAT_Y)
					})
				} if (SCHEDULE.editValues.week_day === 6) {
					setTableData(["6"]);
					setSelectedRowKeys(["6"])
					form.setFieldsValue({
						sun_start_time: moment(SCHEDULE.editValues?.start_date_time.trim(), displayFormats.TIME_FORMAT_Y),
						sun_end_time: moment(SCHEDULE.editValues?.end_date_time.trim(), displayFormats.TIME_FORMAT_Y)
					})
				}
			}
		}, [form, SCHEDULE.editValues])

		return (
			<div>
				<Drawer open={visible} title='Schedule Layout' width={700} destroyOnClose onClose={handleClose}
					className='commanDrawer scheduleDrawer'
					footer={
						[
							<div key="1">
								<Button type='primary' loading={saving} disabled={disabled} form='addEditLayoutForm' className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} htmlType='submit'>Save</Button>
								<Button type='primary' onClick={handleClose} className="orangeBgBtn cancelBtn">Cancel</Button>
							</div>
						]
					}>
					<Spin size="large" spinning={!SCHEDULE.editValues} tip={"Loading"}>
						<FormComponent
							id="addEditLayoutForm"
							form={form}
							type="edit"
							handleSubmit={handleSubmit}
							onChange={handleChange}
							show={show}
							setShow={setShow}
							tableData={tableData}
							setTableData={setTableData}
							setDisabled={setDisabled}
							selectedRowKeys={selectedRowKeys}
							setSelectedRowKeys={setSelectedRowKeys}
						/>
					</Spin>
				</Drawer>
			</div>
		)
	})
export default EditComponent
