import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import PageSpinner from "./components/PageSpinner/PageSpinner";
import Router from "./config/RouterConfig";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import useStore from "./store";
import { TourProvider } from "@reactour/tour";

const App: React.FC = observer(() => {
  const { AUTH } = useStore();
  const { InitializeApp, app_loading } = AUTH;
  const disableBody = (target: any) => disableBodyScroll(target);
  const enableBody = (target: any) => enableBodyScroll(target);

  useEffect(() => {
    InitializeApp();
  }, [InitializeApp]);

  // for hot fix testing 15 oct
  return (
    <BrowserRouter>
      <TourProvider
        steps={[]}
        afterOpen={disableBody}
        beforeClose={enableBody}
        
        // lastStepNextButton={<MyButton>Done! Let's start playing</MyButton>}
      >
        {app_loading ? <PageSpinner /> : <Router />}
      </TourProvider>
    </BrowserRouter>
  );
});

export default App;
