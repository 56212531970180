import React, { useEffect } from "react";
import { Col, Row, Table } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { observer } from "mobx-react";
import { FormInstance } from "antd/es/form/Form";
import { displayFormats, validateFields } from "../../../../config/Global";
import { DataType } from "../../../../store/ScheduleStore/ScheduleInterface";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { scheduleRules } from "../../../../requests/PageRequest";
import useStore from "../../../../store";

interface formInterface {
    id: string;
    form: FormInstance;
    handleSubmit: (data: any) => void;
    onChange: () => void;
    type?: string;
    show: boolean;
    setShow: (data: any) => void;
    setDisabled: (data: any) => void;
    tableData: any;
    setTableData: (data: any) => void;
    selectedRowKeys?: any
    setSelectedRowKeys: (data: any) => void;
}

const FormComponent: React.FC<any> = observer((props: formInterface) => {
    const { form, handleSubmit, onChange, id, show, setShow, setDisabled, tableData, setTableData, type, selectedRowKeys, setSelectedRowKeys } = props;

    const { SCHEDULE } = useStore();

    const handleTimezonChange = () => {
        setDisabled(true)
        if (form.getFieldValue('type') === 1) {
            setTableData([]);
            setSelectedRowKeys([]);
            form.resetFields(['mon_start_time', 'mon_end_time', 'tue_start_time', 'tue_end_time', 'wed_start_time', "wed_end_time", 'thu_start_time', 'thu_end_time', 'fri_start_time', 'fri_end_time', 'sat_start_time', 'sat_end_time', "sun_start_time", 'sun_start_time'])
        } else {
            form.resetFields(['start_date_time', 'end_date_time'])
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: any, selectedRows: DataType[]) => {
            selectedRows;
            setSelectedRowKeys(newSelectedRowKeys)
            setTableData([...newSelectedRowKeys]);
            if (newSelectedRowKeys.length > 0) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        },
        getCheckboxProps: () => {
            const disableRow = type ? true : false;
            return ({
                disabled: disableRow, // Column configuration not to be checked
            })
        },
        columnTitle: <></>,
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "Days",
            dataIndex: "days",
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: "Start Time",
            dataIndex: "start_time",
        },
        {
            title: "End Time",
            dataIndex: "end_time",
        },
    ];

    const disableMonStartDate = (current: any) => {
        return current && current <= moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y));
    }
    const disableMonEndDate = (current: any) => {
        return (form.getFieldValue('mon_start_time') ? current && current <= moment(form.getFieldValue('mon_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
    }

    const disableTueEndDate = (current: any) => {
        return (form.getFieldValue('tue_start_time') ? current && current <= moment(form.getFieldValue('tue_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
    }

    const disableWedEndDate = (current: any) => {
        return (form.getFieldValue('wed_start_time') ? current && current <= moment(form.getFieldValue('wed_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
    }

    const disableThuEndDate = (current: any) => {
        return (form.getFieldValue('thu_start_time') ? current && current <= moment(form.getFieldValue('thu_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
    }
    const disableFriEndDate = (current: any) => {
        return (form.getFieldValue('fri_start_time') ? current && current <= moment(form.getFieldValue('fri_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
    }
    const disablesatEndDate = (current: any) => {
        return (form.getFieldValue('sat_start_time') ? current && current <= moment(form.getFieldValue('sat_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
    }
    const disablesunEndDate = (current: any) => {
        return (form.getFieldValue('sun_start_time') ? current && current <= moment(form.getFieldValue('sun_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
    }

    const data: DataType[] = [
        {
            key: '0',
            days: 'MON',
            start_time: tableData && tableData.includes('0') ? <InputBox.TimePicker allowClear={false} rules={scheduleRules.time_layout}
                onChange={(e: any) => {
                    form.setFieldsValue({ 'mon_start_time': moment(e), 'mon_end_time': moment(e.add(10, 'minute')) });
                    validateFields(form, setDisabled)
                }} disabledDate={disableMonStartDate} name={'mon_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
            end_time: tableData && tableData.includes('0') ? <InputBox.TimePicker allowClear={false} rules={scheduleRules.time_layout} onChange={
                () => { validateFields(form, setDisabled); }} name={'mon_end_time'} format={displayFormats.TIME_FORMAT_Y} disabledDate={disableMonEndDate}></InputBox.TimePicker> : null,
        },
        {
            key: '1',
            days: 'TUE',
            start_time: tableData && tableData.includes('1') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
                (e: any) => {
                    form.setFieldsValue({ 'tue_start_time': moment(e), 'tue_end_time': moment(e.add(10, 'minute')) });
                    validateFields(form, setDisabled);
                }
            } name={'tue_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
            end_time: tableData && tableData.includes('1') ? <InputBox.TimePicker allowClear={false} disabledDate={disableTueEndDate} rules={scheduleRules.time_layout} name={'tue_end_time'} format={displayFormats.TIME_FORMAT_Y} onChange={
                () => { validateFields(form, setDisabled); }} ></InputBox.TimePicker> : null,
        },
        {
            key: '2',
            days: 'WED',
            start_time: tableData && tableData.includes('2') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
                (e: any) => {
                    form.setFieldsValue({ 'wed_start_time': moment(e), 'wed_end_time': moment(e.add(10, 'minute')) });
                    validateFields(form, setDisabled)
                }
            } name={'wed_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
            end_time: tableData && tableData.includes('2') ? <InputBox.TimePicker allowClear={false} disabledDate={disableWedEndDate} rules={scheduleRules.time_layout} name={'wed_end_time'} format={displayFormats.TIME_FORMAT_Y} onChange={
                () => { validateFields(form, setDisabled); }}></InputBox.TimePicker> : null,
        },
        {
            key: '3',
            days: 'THU',
            start_time: tableData && tableData.includes('3') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
                (e: any) => {
                    form.setFieldsValue({ 'thu_start_time': moment(e), 'thu_end_time': moment(e.add(10, 'minute')) });
                    validateFields(form, setDisabled)
                }
            } name={'thu_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
            end_time: tableData && tableData.includes('3') ? <InputBox.TimePicker allowClear={false} disabledDate={disableThuEndDate} rules={scheduleRules.time_layout} name={'thu_end_time'} format={displayFormats.TIME_FORMAT_Y} onChange={
                () => { validateFields(form, setDisabled); }}></InputBox.TimePicker> : null,
        },
        {
            key: '4',
            days: 'FRI',
            start_time: tableData && tableData.includes('4') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
                (e: any) => {
                    form.setFieldsValue({ 'fri_start_time': moment(e), 'fri_end_time': moment(e.add(10, 'minute')) })
                    validateFields(form, setDisabled)
                }
            } name={'fri_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
            end_time: tableData && tableData.includes('4') ? <InputBox.TimePicker allowClear={false} disabledDate={disableFriEndDate} rules={scheduleRules.time_layout} name={'fri_end_time'} format={displayFormats.TIME_FORMAT_Y} onChange={
                () => { validateFields(form, setDisabled); }}></InputBox.TimePicker> : null,

        }, {
            key: '5',
            days: 'SAT',
            start_time: tableData && tableData.includes('5') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
                (e: any) => {
                    form.setFieldsValue({ 'sat_start_time': moment(e), 'sat_end_time': moment(e.add(10, 'minute')) })
                    validateFields(form, setDisabled)
                }
            } name={'sat_start_time'} format={displayFormats.TIME_FORMAT_Y} ></InputBox.TimePicker> : null,
            end_time: tableData && tableData.includes('5') ? <InputBox.TimePicker allowClear={false} disabledDate={disablesatEndDate} rules={scheduleRules.time_layout} name={'sat_end_time'} format={displayFormats.TIME_FORMAT_Y} onChange={
                () => { validateFields(form, setDisabled); }}></InputBox.TimePicker> : null,

        }, {
            key: '6',
            days: 'SUN',
            start_time: tableData && tableData.includes('6') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
                (e: any) => {
                    form.setFieldsValue({ 'sun_start_time': moment(e), 'sun_end_time': moment(e.add(10, 'minute')) })
                    validateFields(form, setDisabled)
                }
            } name={'sun_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
            end_time: tableData && tableData.includes('6') ? <InputBox.TimePicker allowClear={false} disabledDate={disablesunEndDate} rules={scheduleRules.time_layout} name={'sun_end_time'} format={displayFormats.TIME_FORMAT_Y} onChange={
                () => { validateFields(form, setDisabled); }}></InputBox.TimePicker> : null,

        },
    ];

    const disableStartDate = (current: any) => {
        return current && current < moment(moment().add(24, 'hours').subtract(1, 'day').format(displayFormats.DATE_FORMAT_Y));
    }

    const disableEndDate = (current: any) => {
        return (form.getFieldValue('start_date_time') ? current && current <= moment(form.getFieldValue('start_date_time')) : current && current < moment(moment().add(24, 'hours').format(displayFormats.DATE_FORMAT_Y)));
    }

    const handleDateChange = (e: any) => {
        setDisabled(false)
        form.setFieldsValue({ 'end_date_time': moment(e).add(10, 'minutes') })
    }

    useEffect(() => {
        if (SCHEDULE.editValues && type) {
            SCHEDULE?.listSchedule()
            SCHEDULE?.listLayoutSchedule()
        }

    }, [SCHEDULE.editValues])

    return (
        <FormBox form={form} id={id} onFinish={handleSubmit} initialValues={SCHEDULE.initialValue}>
            <Row gutter={15}>
                <Col span={12}>
                    <InputBox.Select
                        showSearch
                        required
                        // className='selectDown'
                        // 			showArrow={false}
                        // 			extra={
                        // 				<DownIcon/>
                        // 			}
                        filterOption={(input: any, option: any) =>
                            (option?.children || {}).toLowerCase().includes(input.toLowerCase())
                        }
                        rules={scheduleRules.select_device}
                        placeholder='Select Device'
                        label='Select Device'
                        name={'device_id'}
                        disabled={type ? true : false}
                        onFocus={() => SCHEDULE?.listLayoutSchedule()}
                        onChange={onChange}
                        options={{
                            list: SCHEDULE?.schedule_layout_list,
                            valueKey: 'id',
                            textKey: 'name',
                            rejectedValues: SCHEDULE?.schedule_layout_list
                                ? SCHEDULE?.schedule_layout_list
                                    ?.filter((item: any) => item?.id === 0)
                                    ?.map((item: any) => item?.id)
                                : null
                        }}

                    ></InputBox.Select>
                </Col>
                <Col span={12}>
                    <InputBox.Select
                        required
                        showSearch
                        // className='selectDown'
                        // 			showArrow={false}
                        // 			extra={
                        // 				<DownIcon/>
                        // 			}
                        placeholder='Select Layout'
                        label='Select Layout'
                        name={'layout_id'}
                        filterOption={(input: any, option: any) =>
                            (option?.children || {}).toLowerCase().includes(input.toLowerCase())
                        }
                        onFocus={() => SCHEDULE?.listSchedule()}
                        rules={scheduleRules.select_layout}
                        onChange={onChange}
                        options={{
                            list: SCHEDULE?.layout_list,
                            valueKey: 'id',
                            textKey: 'name'
                        }}
                    ></InputBox.Select>
                </Col>
                <Col span={7}>
                    <InputBox.Radio
                        required
                        label='Select TimeZone'
                        // rules={scheduleRules.select_layout}
                        buttonStyle='solid'
                        disabled={type ? true : false}
                        onChange={
                            (data: any) => {
                                handleTimezonChange();
                                setShow(data.target.value === 1 ? true : false)
                            }
                        }
                        optionType='button'
                        options={{
                            list: [{ id: 1, name: 'Once' }, { id: 2, name: 'Repeat' }],
                            valueKey: 'id',
                            textKey: 'name'
                        }}
                        name={'type'}
                    ></InputBox.Radio>
                </Col>
                {show ? (
                    <>
                        <Col span={8}>
                            <InputBox.DateTimePicker
                                required
                                rules={scheduleRules.select_layout}
                                format={displayFormats.DATE_FORMAT}
                                onChange={(e: any) => {
                                    // onChange()
                                    handleDateChange(e)
                                    // form.setFieldsValue({ 'end_date': moment(e).add(10, 'minutes') })
                                }}
                                allowClear={false}
                                label='Start Date & Time'
                                disabledDate={disableStartDate}
                                name={'start_date_time'}
                            >
                            </InputBox.DateTimePicker>
                        </Col>
                        <Col span={8}>
                            <InputBox.DateTimePicker
                                required
                                allowClear={false}
                                rules={scheduleRules.select_layout}
                                onChange={onChange}
                                format={displayFormats.DATE_FORMAT}
                                label='End Date & Time'
                                disabledDate={disableEndDate}
                                name={'end_date_time'}
                            >
                            </InputBox.DateTimePicker>
                        </Col>
                    </>)
                    :
                    <Col span={24}>
                        <Table
                            className="schedule-layout"
                            rowSelection={rowSelection}
                            pagination={false}
                            columns={columns}
                            dataSource={data}
                        />
                    </Col>
                }
            </Row>
        </FormBox>
    );
});

export default FormComponent;