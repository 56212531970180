import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
// import '@fullcalendar/core/main.css'
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
// import '@fullcalendar/list/main.css';
import useStore from "../../../../store";
import moment from "moment";
import { Tooltip } from "antd";
import { ListScheduleProps } from "../../../../store/ScheduleStore/ScheduleInterface";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CONSTANT } from "../../../../config/Constant";

const Calandercomponent: React.FC<ListScheduleProps> = (
  props: ListScheduleProps
) => {
  const { SCHEDULE, AUTH, SCHEDULE: { setStartDate, setEndDate } } = useStore();
  const { deviceId, setDeviceId, events, setEvents, openEditModal, openDeleteModal } = props;
  const [firstRender, setFirstRender] = useState<boolean>(true);

  function renderEventContent(eventInfo: any) {

    const typeOverride: boolean = (eventInfo.event.extendedProps.type === CONSTANT.SCHEDULE.TYPE.OVERRIDE)

    const tooltipTitle = (< >
      <p>{eventInfo.event.extendedProps.description}</p>
      {(AUTH.checkPermission(11, 'edit') || AUTH.checkPermission(11, 'delete')) && (
        <div className="scheduleCalenderTooltip">
          {AUTH.checkPermission(11, 'edit') && (!typeOverride) && <div className={"buttons"} onClick={() => openEditModal(eventInfo?.event?.extendedProps?.schedule_id)}><EditOutlined /></div>}
          {AUTH.checkPermission(11, 'delete') && <div className={"buttons"} onClick={() => openDeleteModal(eventInfo?.event?.extendedProps?.schedule_id)}><DeleteOutlined /></div>}
        </div>
      )}
    </>)
    return (
      <>
        {eventInfo.event.allDay ? (
          <Tooltip
            title={tooltipTitle}
          >
            <div style={{ height: '100%', width: "100%" }} onDoubleClick={() => { AUTH.checkPermission(11, 'edit') && (!typeOverride) && openEditModal(eventInfo?.event?.extendedProps?.schedule_id) }}>
              <p style={{ marginBottom: 0, paddingBottom: "1rem" }}>{eventInfo.event.title}</p>
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title={tooltipTitle}
            placement="top"
          >
            <div style={{ height: '100%', width: "100%" }} onDoubleClick={() => { AUTH.checkPermission(11, 'edit') && (!typeOverride) && openEditModal(eventInfo?.event?.extendedProps?.schedule_id) }}>
              <p style={{ marginBottom: 0, paddingBottom: "1rem" }}>{eventInfo.timeText}</p>
            </div>
          </Tooltip>
        )}
      </>
    );
  }

  const datesSet = (data: any) => {

    if (firstRender) {
      setFirstRender(false)
      setDeviceId(CONSTANT.SCHEDULE.ALL_DEVICES)
    }
    setStartDate(data.startStr);
    setEndDate(data.endStr);
    const startDate = moment(data.startStr).format("YYYY-MM-DD");
    const endDate = moment(data.endStr).format("YYYY-MM-DD");

    const payload = {
      device_id: deviceId ?? CONSTANT.SCHEDULE.ALL_DEVICES,
      start_date: startDate,
      end_date: endDate,
    };
    SCHEDULE?.listDeviceSchedule(payload).then((data: any) => {
      setEvents(data);
    });
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        headerToolbar={{
          left: "title",
          right: "prev,next today dayGridDay,timeGridWeek,dayGridMonth",
        }}
        buttonText={{ month: 'Month', day: 'Day', week: 'Week' }}
        initialView="timeGridWeek"
        events={events}
        eventContent={renderEventContent}
        datesSet={datesSet}
        eventTimeFormat={ // like '14:30:00'
          {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          }
        }
      />
    </>
  );
};
export default Calandercomponent;
