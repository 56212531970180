import React, { useEffect, useState } from 'react'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { ScheduleBreadcrumb } from '../../../config/BreadcrumbConfig'
import { Button, Col, PageHeader, Row, Form, Tooltip } from 'antd'
import { observer } from 'mobx-react'
import AddComponent from './components/AddComponent'
import { FormBox, InputBox } from '../../../components/AntdAddons'
import useStore from '../../../store'
// import { Notification } from '../../../config/Global'
import Calandercomponent from './components/Calandercomponent'
import moment from 'moment'
import Unauthorized from '../../errors/Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faList } from '@fortawesome/free-solid-svg-icons'
import ListComponent from './components/ListComponent'
import RecordPerPage from '../../../components/AgGridWrapper/RecordPerPage'
import { CONSTANT } from '../../../config/Constant'
import EditComponent from './components/EditComponent'
import DeleteComponent from './components/DeleteComponent'
// import { ReactComponent as DownIcon } from '../../../assets/images/icon/downIcon.svg';

const Schedule: React.FC = observer(() => {
	const [addmodal, setaddModal] = useState<boolean>(false)
	const { SCHEDULE, AUTH, SCHEDULE: { selectedComponent, setSelectedComponent, startDate, endDate } } = useStore()
	const [events, setEvents] = useState('')
	const [editDrawer, setEditDrawer] = useState<boolean>(false)
	const [deleteDrawer, setDeleteDrawer] = useState<boolean>(false)
	const [deviceId, setDeviceId] = useState<number | null>();
	const [form] = Form.useForm()

	const openEditDrawer = (data: any) => {
		SCHEDULE.ReadData(data)
		setEditDrawer(data)
	}

	const closeEditDrawer = () => {
		SCHEDULE.setEditValues(null)
		setEditDrawer(false)
	}

	const openDeleteDrawer = (data: any) => {
		SCHEDULE.setDeleteValues(data)
		setDeleteDrawer(true)
	}

	const closeDeleteDrawer = () => {
		SCHEDULE.setDeleteValues(null)
		setDeleteDrawer(false)
	}

	const handleChange = (e: number) => {
		setDeviceId(e)
		const startdate = startDate && moment(startDate).format('YYYY-MM-DD')
		const enddate = endDate && moment(endDate).format('YYYY-MM-DD')
		const payload = {
			device_id: e,
			start_date: startdate,
			end_date: enddate,
		}
		SCHEDULE?.listDeviceSchedule(payload).then((data: any) => {
			setEvents(data)
		}).catch(() => {
			// Notification.error({
			// 	message: e?.data
			// })
		})
	}
	const handleClose = () => {
		setaddModal(false)
	}

	useEffect(() => {
		setSelectedComponent("calendar");
	}, [])

	useEffect(() => {
		if (selectedComponent === "calendar") {
			SCHEDULE?.listLayoutSchedule("calendar").then((data: any) => {
				if (data.data && data.data?.length > 0) {
					form.setFieldValue("device_id", CONSTANT.SCHEDULE.ALL_DEVICES)
					setDeviceId(CONSTANT.SCHEDULE.ALL_DEVICES)
				}
			})
		} else {
			form.setFieldValue("device_id", null)
			setDeviceId(null)
		}
	}, [selectedComponent])

	return (
		<div className='scheduleLayout'>
			{AUTH.checkPermission(11, 'list') ?
				<PageHeader title={ScheduleBreadcrumb.title}
					tags={<BreadcrumbComponent items={ScheduleBreadcrumb.path} />}
					extra={[
						AUTH.checkPermission(11, 'add') && <Button key={1} type='primary' onClick={() => { setaddModal(true) }}>
							+ Add Event
						</Button>
					]}>
					<Row className='listIcon' gutter={[15, 15]}>
						<Tooltip title={"Calendar View"}><Button className={selectedComponent === "calendar" ? "selected" : ""} onClick={() => { setSelectedComponent("calendar") }}><FontAwesomeIcon icon={faCalendar} /></Button></Tooltip>
						<Tooltip title={"List View"}><Button className={selectedComponent === "list" ? "selected" : ""} onClick={() => { setSelectedComponent("list") }}><FontAwesomeIcon icon={faList} /></Button></Tooltip>
						{selectedComponent === "list" && (<RecordPerPage
							defaultValue={SCHEDULE.per_page + " per page"}
							onChange={SCHEDULE.setPageSize}
						/>)}
						{selectedComponent === "calendar" && (
							<div className='scheduling-calender'>

								<FormBox className='scheduleForm' form={form}>
									<Row gutter={15}>
										<Col span={12} xs={8}>
											<InputBox.Select
												showSearch
												placeholder='Select Device'
												label='Select Device'
												name={'device_id'}
												// className='selectDown'
												// showArrow={false}
												// extra={
												//     <DownIcon/>
												// }
												filterOption={(input: any, option: any) =>
													(option?.children || {}).toLowerCase().includes(input.toLowerCase())
												}
												onChange={handleChange}
												options={{
													list: SCHEDULE?.calendar_device_list,
													valueKey: 'id',
													textKey: 'name'
												}}
											></InputBox.Select>
										</Col>
									</Row>
								</FormBox>
							</div>
						)}

					</Row>
					{selectedComponent === "list" && (
						<>
							<ListComponent openEditModal={openEditDrawer} openDeleteModal={openDeleteDrawer} />
						</>
					)}
					{selectedComponent === "calendar" && (
						<div className='calendarComponent'>
							<Calandercomponent events={events} setEvents={setEvents} openEditModal={openEditDrawer} openDeleteModal={openDeleteDrawer} deviceId={deviceId} setDeviceId={setDeviceId} />
						</div>
					)}
					<AddComponent visible={addmodal} close={handleClose} setEvents={setEvents} deviceId={deviceId} />
					<EditComponent visible={editDrawer} close={closeEditDrawer} setEvents={setEvents} deviceId={deviceId} />
					<DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} setEvents={setEvents} deviceId={deviceId} />
				</PageHeader>
				: <Unauthorized />}
		</div>
	)
})

export default Schedule